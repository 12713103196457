import React, {useContext} from 'react';
import clsx from 'clsx';
import compact from 'lodash/compact';
import Button from 'shared/ui/atoms/button/tertiary';
import DropDownButton from 'shared/ui/molecules/button/dropdown';
import sortLanguages from 'candidate/advanced/shared/sortLanguages';
import i18n, {getQueryParamsWithLng} from 'candidate/i18n';
import AccountDetails from 'candidate/providers/account/details';
import Locale from 'candidate/providers/locale/careers';
import styles from './styles.scss';
import text from './text';

const language = (lng, mode = 'desktop') => {
  if (!lng) {
    return;
  }
  const lngKey = Object.keys(text).find(l => lng.includes(l));
  return (text && lngKey && text[lngKey] && text[lngKey][mode]) || null;
};

const LanguagePicker = ({as: ButtonFlavor = Button, className, defaultLanguage, disabled, languages}) => {
  const {locale} = useContext(Locale) || {};
  const languageExists = l => languages && languages.find(lng => l && l.includes(lng));
  const selected = languageExists(locale) ? locale : defaultLanguage;

  const options = languages.map((lng, i) => {
    const isSelected = selected && selected.includes && selected.includes(lng);

    // Currently the dropdown component does not support rendering <a />, since it prevents the event propagation.
    // Instead we use window.location.search = replacement.
    return (
      <Button key={i} onClick={() => (window.location.search = getQueryParamsWithLng(lng))} selected={isSelected}>
        <div className={clsx(styles.options, {[styles.optionColor]: isSelected})} data-ui={lng}>
          <div className={styles.optionsText}>{language(lng)}</div>
        </div>
      </Button>
    );
  });

  const desktopLanguage = language(selected || 'en');
  const mobileLanguage = language(selected || 'en', 'mobile');
  return (
    <div className={clsx(compact([styles.picker, className]))}>
      <DropDownButton
        aria-label={i18n.t('i18n.picker', {language: desktopLanguage})}
        as={ButtonFlavor}
        disabled={disabled}
        right
        type="button"
      >
        <span className={styles.selected} data-ui="language-picker">
          <span className={styles.desktopLanguage}>{desktopLanguage}</span>
          <span className={styles.mobileLanguage}>{mobileLanguage}</span>
        </span>
        {options}
      </DropDownButton>
    </div>
  );
};

export const getVisibleLanguages = options => {
  if (typeof options.find(() => true) === 'string') {
    // backwords compatibility
    return options;
  }

  return options.filter(option => !!option.visible).map(option => option.name);
};

const _LanguagePicker = ({allLanguages, ...rest}) => {
  const {accountDetails: {languages: {default: defaultLanguage, options} = {}} = {}} = useContext(AccountDetails) || {};
  if (!options) {
    return null;
  }

  const displayLanguages = allLanguages ? options.map(l => l.name) : getVisibleLanguages(options);

  if (displayLanguages.length < 2) {
    return null;
  }

  const sortedLanguages = sortLanguages({
    defaultLanguage,
    languages: displayLanguages.map(l => ({displayedName: language(l), name: l}))
  }).map(l => l.name);

  return <LanguagePicker defaultLanguage={defaultLanguage || 'en'} languages={sortedLanguages} {...rest} />;
};

export default _LanguagePicker;
