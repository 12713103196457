import format from 'date-fns/format';
import formatDistance from 'date-fns/formatDistance';
import isAfter from 'date-fns/isAfter';
import isToday from 'date-fns/isToday';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import qs from 'query-string';
import {initReactI18next} from 'react-i18next';
import {messages as applicationFormMessages} from 'shared/components/applicationForm/i18n';
import {folder} from 'candidate/shared/import';

export const messages = folder(require.context('./messages', true, /\.js$/));

const sharedMessages = folder(require.context('../../../shared/ui/locales', true, /\.js$/));
const sharedLocales = Object.freeze(Object.keys(sharedMessages));

export const LOCALES = Object.freeze(Object.keys(messages));

export const DATE_LOCALES = {};

LOCALES.forEach(
  locale => (DATE_LOCALES[locale] = require(`date-fns/locale/${locale === 'en' ? 'en-US' : locale}/index.js`))
);

const getOverriddenFormattedDistance = locale => {
  const fewHoursAgoMessage = messages[locale].advanced.component?.job?.posted_few_hours_ago;

  return {
    lessThanXSeconds: fewHoursAgoMessage,
    xSeconds: fewHoursAgoMessage,
    halfAMinute: fewHoursAgoMessage,
    lessThanXMinutes: fewHoursAgoMessage,
    xMinutes: fewHoursAgoMessage,
    aboutXHours: fewHoursAgoMessage
  };
};

const order = ['htmlTag'];

if (__ENV__ === 'e2e') {
  order.push('querystring');
}

const _init = i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    interpolation: {
      escapeValue: false
    },
    detection: {
      order
    },
    fallbackLng: {
      'en-AU': ['en'],
      'en-BZ': ['en'],
      'en-CA': ['en'],
      'en-CB': ['en'],
      'en-GB': ['en'],
      'en-IN': ['en'],
      'en-IE': ['en'],
      'en-JM': ['en'],
      'en-NZ': ['en'],
      'en-PH': ['en'],
      'en-ZA': ['en'],
      'en-TT': ['en'],
      'en-US': ['en'],
      'fr-BE': ['fr'],
      'fr-CA': ['fr'],
      'fr-LU': ['fr'],
      'fr-CH': ['fr'],
      'de-AT': ['de'],
      'de-DE': ['de'],
      'de-LI': ['de'],
      'de-LU': ['de'],
      'de-CH': ['de'],
      'es-BT': ['es'],
      'es-CO': ['es'],
      'es-CR': ['es'],
      'es-MX': ['es'],
      'es-PY': ['es'],
      'es-PE': ['es'],
      'es-ES': ['es'],
      'pt-BR': ['pt'],
      'pt-PT': ['pt'],
      default: ['en']
    }
  });

LOCALES.forEach(locale => {
  i18next.addResourceBundle(locale, 'translation', messages[locale], true, true);
  i18next.addResourceBundle(locale, 'translation', applicationFormMessages[locale], true, false);
});

sharedLocales.forEach(locale => i18next.addResourceBundle(locale, 'shared', sharedMessages[locale]));

i18next.getSharedResources = (locale = i18next.language) => {
  if (!sharedMessages) {
    return;
  }

  const chosenLanguage = sharedLocales.find(sharedLocale => sharedLocale === locale);

  return i18next.getResourceBundle(chosenLanguage || 'en', 'shared');
};

const formatDistanceOverride = (defaultFormatDistance, locale) => (token, count, options) => {
  const overriddenFormattedDistance = getOverriddenFormattedDistance(locale)[token];
  if (overriddenFormattedDistance && options?.addSuffix && options?.comparison <= 0) {
    return overriddenFormattedDistance;
  }

  return defaultFormatDistance(token, count, options);
};

i18next.formatDistance = (date, locale = i18next.language) => {
  if (!date) {
    return '';
  }

  date = date instanceof Date ? date : new Date(date);

  if (isToday(date)) {
    const t = i18next.getFixedT(locale);
    return t('today');
  }

  const formatDistanceLocale = DATE_LOCALES[locale] && {
    ...DATE_LOCALES[locale],
    formatDistance: formatDistanceOverride(DATE_LOCALES[locale].formatDistance, locale)
  };

  const now = Date.now();
  return formatDistance(isAfter(date, now) ? now : date, now, {
    addSuffix: true,
    locale: formatDistanceLocale
  });
};

i18next.localizedFormatDistance = (firstDate, secondDate, options = {}) =>
  formatDistance(firstDate, secondDate, {...options, locale: DATE_LOCALES[i18next.language]});

i18next.localizedFormat = (date, dateFormat, options = {}) =>
  format(date, dateFormat, {...options, locale: DATE_LOCALES[i18next.language]});

export const alterHtmlLangAttribute = (language, html = window.document.querySelector('html')) =>
  Promise.resolve(html && html.setAttribute('lang', language));

export const changeLanguage = language => alterHtmlLangAttribute(language).then(() => i18next.changeLanguage(language));

i18next.on('languageChanged', alterHtmlLangAttribute);

export const getQueryParamsWithLng = lng => qs.stringify({...(qs.parse(window.location.search || '') || {}), lng});

export const init = () => _init;

export default i18next;
